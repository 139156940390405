// src/components/FacturaList.jsx
import React, { useEffect, useState, useContext, useRef } from 'react';
import api from '../api';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDollarSign, faTrash, faSearch, faPlus, faMagnifyingGlass, faClipboardList, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const FacturaList = () => {

    const { currentUser } = useContext(UserContext);
    const { id, role } = currentUser || {}
    const location = useLocation();
    const [facturas, setFacturas] = useState([]);
    const [clientes, setClientes] = useState({}); // Estado para almacenar los nombres de clientes basados en el RUT
    const [searchTerm, setSearchTerm] = useState('')
    const [totalesAbonos, setTotalesAbonos] = useState({})
    const params = new URLSearchParams(location.search);
    const initialYear = params.get('year') ? Number(params.get('year')) : new Date().getFullYear();
    const initialMonth = params.get('month') ? Number(params.get('month')) : (new Date().getMonth() + 1);

    const [selectedYear, setSelectedYear] = useState(initialYear);
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [useDateRange, setUseDateRange] = useState(false)  // Controlar si se filtra por rango de fechas
    const [estadoFilter, setEstadoFilter] = useState('Todas');
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [mensaje, setMensaje] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const isUserChangingFilters = useRef(false);

    const [sortField, setSortField] = useState(''); // Campo por el cual ordenar
    const [sortOrder, setSortOrder] = useState('asc'); // Orden: 'asc' o 'desc'

    const [selectedFacturas, setSelectedFacturas] = useState([]);


    // Estados para el resumen
    const [resumen, setResumen] = useState({
        cantidadFacturas: 0,
        totalMonto: 0,
        totalPagado: 0,
        totalPendiente: 0,
    })

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let year = params.get('year');
        let month = params.get('month');
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        let shouldNavigate = false;

        // Si no hay año en la URL, establecer el año actual
        if (!year) {
            year = currentYear;
            params.set('year', year);
            shouldNavigate = true;
        }

        // Si no hay mes en la URL, establecer el mes actual
        if (!month) {
            month = currentMonth;
            params.set('month', month);
            shouldNavigate = true;
        }

        // Si faltaban parámetros, actualizar la URL
        if (shouldNavigate) {
            navigate(`?${params.toString()}`, { replace: true });
            return; // Evitar ejecutar el resto del useEffect en esta iteración
        }

        // Establecer los estados desde la URL
        setSelectedYear(Number(year));
        setSelectedMonth(Number(month));

        // Cargar facturas solo si el cambio NO fue realizado por el usuario
        if (!isUserChangingFilters.current) {
            fetchFacturas(); // Asegúrate de tener esta función definida
        } else {
            isUserChangingFilters.current = false; // Resetear la referencia
        }

    }, [location.search, navigate]);

    // useEffect para recalcular el resumen cada vez que cambie 'facturas'
    useEffect(() => {
        calcularResumen(facturas);
    }, [facturas]);

    const handleYearChange = (e) => {
        isUserChangingFilters.current = true; // Indica que el cambio es realizado por el usuario
        const year = Number(e.target.value);
        setSelectedYear(year);
        const params = new URLSearchParams(location.search);
        params.set('year', year);
        navigate(`?${params.toString()}`);
    };

    const handleMonthChange = (e) => {
        isUserChangingFilters.current = true; // Indica que el cambio es realizado por el usuario
        const month = Number(e.target.value);
        setSelectedMonth(month);
        const params = new URLSearchParams(location.search);
        params.set('month', month);
        navigate(`?${params.toString()}`);
    };


// Función para manejar el abono de una factura
const handleFacturaAbonar = (factura) => {
    navigate('/abonos', {
        state: { 
            factura, 
            from: 'FacturaList', 
            search: location.search 
        },
        replace: false
    });
};



    // ELIMINACION MASIVA DE FACTURAS
    const handleSelectFactura = (id) => {
        setSelectedFacturas((prev) =>
            prev.includes(id) ? prev.filter(facturaId => facturaId !== id) : [...prev, id]
        );
    };


    const handleSelectAll = () => {
        if (selectedFacturas.length === facturas.length) {
            setSelectedFacturas([]);
        } else {
            setSelectedFacturas(facturas.map(factura => factura._id));
        }
    };


    const handleDeleteSelected = async () => {
        if (selectedFacturas.length === 0) return;

        Swal.fire({
            title: `¿Estás seguro de eliminar ${selectedFacturas.length} factura(s)?`,
            text: "Esta acción no se puede deshacer.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete('/facturas/bulk-delete', { data: { ids: selectedFacturas } });
                    // Actualizar el estado para reflejar las eliminaciones
                    setFacturas(prev => prev.filter(factura => !selectedFacturas.includes(factura._id)));
                    setSelectedFacturas([]);
                    Swal.fire(
                        'Eliminado!',
                        'Las facturas han sido eliminadas.',
                        'success'
                    );
                } catch (error) {
                    console.error('Error al eliminar facturas:', error);
                    Swal.fire(
                        'Error',
                        'Hubo un problema al eliminar las facturas.',
                        'error'
                    );
                }
            }
        });
    };

    // ELIMINACION MASIVA DE FACTURAS


    const filteredFacturas = facturas.filter(factura => {
        //console.log('Facturas:', facturas); // Depuración
        const clienteNombre = clientes[factura.clienteRut] || '';

        return (
            factura.numero.toString().includes(searchTerm) ||
            clienteNombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
            factura.clienteRut.includes(searchTerm)
        );
    })


    // Función para formatear la fecha en DD-MM-YYYY
    const formatearFecha = (fechaISO) => {
        if (!fechaISO) return 'N/A'; // Si no hay fecha, retornamos 'N/A'
        const fecha = new Date(fechaISO);
        const dia = String(fecha.getUTCDate()).padStart(2, '0');
        const mes = String(fecha.getUTCMonth() + 1).padStart(2, '0'); // Los meses son base 0, por eso sumamos 1
        const anio = fecha.getUTCFullYear();
        return `${dia}-${mes}-${anio}`;
    };


    // Obtener todas las facturas
    // useEffect(() => {
    //     const fetchFacturas = async () => {
    //         const response = await api.get('http://localhost:5000/api/facturas');
    //         setFacturas(response.data);
    //     };
    //     fetchFacturas();
    // }, []);




    const fetchFacturas = async () => {
        const token = localStorage.getItem("token");

        setLoading(true);
        let url = 'https://sistemaoh-backend.onrender.com/api/facturas';
        let queryAdded = false; // Variable de control para manejar '?'

        // if (useDateRange && startDate && endDate) {
        //     url += `?startDate=${startDate}&endDate=${endDate}`;

        if (useDateRange) {
            // Añadir '?' antes del primer parámetro
            if (fechaInicio || fechaFin || (estadoFilter && estadoFilter !== 'todas')) {
                url += '?';
                queryAdded = true;
            }

            if (fechaInicio) {
                url += `fechaInicio=${fechaInicio}`;
                queryAdded = true;
            }
            if (fechaFin) {
                url += `${queryAdded ? '&' : ''}fechaFin=${fechaFin}`;
                queryAdded = true;
            }
            if (estadoFilter && estadoFilter !== 'todas') {
                url += `${queryAdded ? '&' : ''}estado=${estadoFilter}`;
                queryAdded = true;
            }
        } else if (selectedYear && selectedMonth) {
            url += `?year=${selectedYear}&month=${selectedMonth}`;
        } else {
            console.warn('No se han proporcionado filtros válidos.');
            setLoading(false);
            return;
        }

        try {
            const response = await api.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`, // Asegúrate de que el token sea precedido por "Bearer "
                }
            });

            if (Array.isArray(response.data) && response.data.length > 0) {
                setMensaje('');
                setFacturas(response.data);
            } else {
                setMensaje('No hay facturas para mostrar con los filtros seleccionados.');
                setFacturas([]);
            }
        } catch (error) {
            console.error('Error fetching facturas:', error);
            setMensaje('Error al cargar las facturas.');
            setFacturas([]);
        } finally {
            setLoading(false);
        }
    };



    // Función para calcular los datos del resumen
    const calcularResumen = (datos) => {
        const cantidadFacturas = datos.length;
        const totalMonto = datos.reduce((acc, curr) => acc + (curr.monto || 0), 0);

        // Filtrar facturas por estado
        const facturasPagadas = datos.filter(factura => factura.estado === 'pagada');
        const facturasAbonadas = datos.filter(factura => factura.estado === 'abonada');
        const facturasPendientes = datos.filter(factura => factura.estado === 'pendiente');

        // Calcular total pagado:
        // - Sumar montos de facturas completamente pagadas
        // - Sumar montos pagados de facturas parcialmente pagadas
        const totalPagado = facturasPagadas.reduce((acc, factura) => acc + (factura.monto || 0), 0) +
            facturasAbonadas.reduce((acc, factura) => acc + (factura.pagado || 0), 0);

        // Calcular total pendiente:
        // - Sumar montos de facturas completamente pendientes
        // - Sumar diferencias entre monto y pagado de facturas parcialmente pagadas
        const totalPendiente = facturasPendientes.reduce((acc, factura) => acc + (factura.monto || 0), 0) +
            facturasAbonadas.reduce((acc, factura) => acc + ((factura.monto || 0) - (factura.pagado || 0)), 0);

        setResumen({
            cantidadFacturas,
            totalMonto,
            totalPagado,
            totalPendiente,
        });
    }


    const loadFacturas = async () => {
        setLoading(true);
        let url = 'https://sistemaoh-backend.onrender.com/api/facturas';

        if (useDateRange && startDate && endDate) {
            url += `?startDate=${startDate}&endDate=${endDate}`;
        } else {
            url += `?year=${selectedYear}&month=${selectedMonth}`;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await api.get(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFacturas(response.data);
            setMensaje('');
        } catch (error) {
            console.error('Error al obtener facturas:', error);
            setMensaje('No se pudieron cargar las facturas.');
            Swal.fire('Error', 'No se pudieron cargar las facturas.', 'error');
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        fetchFacturas();
    }, [id, role])


    useEffect(() => {
        const fetchClientes = async () => {
            setLoading(true);
            try {
                // Obtener el token del almacenamiento local
                const token = localStorage.getItem('token');
                const response = await api.get('/clientes', {
                    headers: {
                        'Authorization': `Bearer ${token}` // Agregar el token aquí
                    }
                });

                const clienteMap = {};
                response.data.forEach(cliente => {
                    clienteMap[cliente.rut] = cliente.nombre;
                });
                setClientes(clienteMap);
            } catch (error) {
                console.error('Error al obtener los clientes:', error);
                setMensaje('Error al cargar los clientes. Intente nuevamente.');
            } finally {
                setLoading(false);
            }
        }
        fetchClientes();
    }, []);

    const eliminarFactura = async (id) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                await api.delete(`/facturas/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}` // Asegúrate de incluir el token en la eliminación
                    }
                });

                setFacturas(facturas.filter(factura => factura._id !== id));
                Swal.fire('Eliminado!', 'La factura ha sido eliminada.', 'success');

            } catch (error) {
                console.error('Error al eliminar la factura:', error);
                Swal.fire('Error!', 'No se pudo eliminar la factura.', 'error');
            }
        }
    }


    const handleSort = (field) => {
        if (sortField === field) {
            // Si se hace clic en el mismo campo, alterna el orden
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Si se hace clic en un campo diferente, ordena ascendente
            setSortField(field);
            setSortOrder('asc');
        }
    };


    // Ordenar las facturas filtradas
    const sortedFacturas = [...filteredFacturas].sort((a, b) => {
        if (!sortField) return 0; // Sin campo de ordenamiento, no ordenar

        let aField = a[sortField];
        let bField = b[sortField];

        // Si el campo es 'clienteNombre', obtenemos el nombre del cliente
        if (sortField === 'clienteNombre') {
            aField = clientes[a.clienteRut] || '';
            bField = clientes[b.clienteRut] || '';
        } else if (sortField.includes('fecha')) {
            // Para campos de fecha, convertir a objetos Date
            aField = aField ? new Date(aField) : new Date(0);
            bField = bField ? new Date(bField) : new Date(0);
        } else if (typeof aField === 'string') {
            // Convertir a minúsculas para comparar cadenas de texto
            aField = aField.toLowerCase();
            bField = bField.toLowerCase();
        }

        if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
        if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });




    return (
        <div className="p-6 min-h-screen bg-gray-50">
            <h1 className="font-bold mb-6 text-center text-3xl text-gray-800">Lista de Facturas</h1>


            {/* Cuadro de Resumen */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-8">
                <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
                    <FontAwesomeIcon icon={faClipboardList} className="text-blue-500 text-2xl mr-3" />
                    <div>
                        <h3 className="text-sm font-medium text-gray-700">Total Facturas</h3>
                        <p className="text-lg font-semibold text-blue-600">{resumen.cantidadFacturas}</p>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
                    <FontAwesomeIcon icon={faDollarSign} className="text-green-500 text-2xl mr-3" />
                    <div>
                        <h3 className="text-sm font-medium text-gray-700">Monto Total</h3>
                        <p className="text-lg font-semibold text-green-600">${resumen.totalMonto.toLocaleString('es-CL')}</p>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
                    <FontAwesomeIcon icon={faDollarSign} className="text-yellow-500 text-2xl mr-3" />
                    <div>
                        <h3 className="text-sm font-medium text-gray-700">Total Pagado</h3>
                        <p className="text-lg font-semibold text-yellow-600">${resumen.totalPagado.toLocaleString('es-CL')}</p>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 text-2xl mr-3" />
                    <div>
                        <h3 className="text-sm font-medium text-gray-700">Total Pendiente</h3>
                        <p className="text-lg font-semibold text-red-600">${resumen.totalPendiente.toLocaleString('es-CL')}</p>
                    </div>
                </div>
            </div>




            <div className="bg-white p-6 rounded-lg shadow-md mb-6">

                {/* Botón Nueva Factura */}
                <div className="flex justify-end mb-4">
                    <button
                        onClick={() => navigate('/facturas/nueva', {
                            state: { from: 'FacturaList', search: location.search },
                        })
                        }
                        className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md shadow hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Nueva Factura
                    </button>
                </div>

                <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-6">
                    {/* Filtros Simples o Avanzados */}
                    <div className="w-full">
                        {/* Toggle para cambiar entre mes/año o búsqueda avanzada */}
                        <div className="mb-4">
                            <label className="mb-2 text-gray-700 font-medium">Filtrar por:</label>
                            <div className="flex items-center space-x-4">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="filterType"
                                        checked={!useDateRange}
                                        onChange={() => setUseDateRange(false)}
                                        className="form-radio h-4 w-4 text-blue-600"
                                    />
                                    <span>Mes/Año</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="filterType"
                                        checked={useDateRange}
                                        onChange={() => setUseDateRange(true)}
                                        className="form-radio h-4 w-4 text-blue-600"
                                    />
                                    <span>Búsqueda Avanzada</span>
                                </label>
                            </div>
                        </div>

                        {/* Controles de Mes/Año */}
                        {!useDateRange && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {/* Selector de Año */}
                                <div className="flex flex-col">
                                    <label className="mb-1 text-gray-600">Año</label>
                                    <select
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        {Array.from({ length: 10 }, (_, i) => {
                                            const year = new Date().getFullYear() - i;
                                            return (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                {/* Selector de Mes */}
                                <div className="flex flex-col">
                                    <label className="mb-1 text-gray-600">Mes</label>
                                    <select
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        className="mt-1 block w-24 sm:w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                                            <option key={month} value={month}>
                                                {month.toString().padStart(2, '0')}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}

                        {/* Controles de Búsqueda Avanzada */}
                        {useDateRange && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                                {/* Rango de Fechas */}
                                <div className="flex flex-col">
                                    <label className="mb-1 text-gray-600">Rango de Fechas:</label>
                                    <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                                        <input
                                            type="date"
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}
                                            className="w-32 sm:w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                        <input
                                            type="date"
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}
                                            className="w-32 sm:w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                </div>

                                {/* Selección de Estado */}
                                <div className="flex flex-col">
                                    <label className="mb-1 text-gray-600">Estado de la Factura:</label>
                                    <select
                                        value={estadoFilter}
                                        onChange={(e) => setEstadoFilter(e.target.value)}
                                        className="block w-40 sm:w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="todas">Todas</option>
                                        <option value="pagada">Pagada</option>
                                        <option value="abonada">Abonada</option>
                                        <option value="pendiente">Pendiente</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Botones de Acción */}
                    <div className="flex items-start">

                        {/* Botón Cargar Facturas */}
                        <button
                            onClick={fetchFacturas}
                            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none whitespace-nowrap"
                        >
                            <FontAwesomeIcon icon={faSearch} className="mr-2" />
                            Cargar Facturas
                        </button>
                    </div>
                </div>
            </div>




            {/* Barra de Búsqueda */}
            <div className="flex items-center justify-between mb-4">
                <div className="relative w-full max-w-md">
                    <input
                        type="text"
                        placeholder="Buscar por número, nombre o RUT"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="border border-gray-300 p-2 pl-10 rounded-full w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <span className="absolute left-3 top-2">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-400" />
                    </span>
                </div>

                {/* BOTON PARA ELIMINAR FACTURAS*/}
                <button
                    onClick={handleDeleteSelected}
                    disabled={selectedFacturas.length === 0 || loading}
                    className={`ml-4 bg-red-500 text-white px-4 py-2 rounded ${selectedFacturas.length === 0 || loading
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:bg-red-600'
                        }`}
                >
                    {loading ? 'Eliminando...' : 'Eliminar Seleccionadas'}
                </button>
                {/* BOTON PARA ELIMINAR FACTURAS*/}
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-lg shadow-md">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-center">
                                <input
                                    type="checkbox"
                                    checked={selectedFacturas.length === facturas.length}
                                    onChange={handleSelectAll}
                                    className="form-checkbox h-4 w-4 text-blue-600"
                                />
                            </th>
                            <th
                                className="py-3 px-6 text-left cursor-pointer"
                                onClick={() => handleSort('numero')}
                            >
                                Número {sortField === 'numero' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className="py-3 px-6 text-left cursor-pointer"
                                onClick={() => handleSort('clienteRut')}
                            >
                                RUT Cliente {sortField === 'clienteRut' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className="py-3 px-6 text-left cursor-pointer"
                                onClick={() => handleSort('clienteNombre')}
                            >
                                Nombre Cliente {sortField === 'clienteNombre' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className="py-3 px-6 text-left cursor-pointer"
                                onClick={() => handleSort('fechaEmision')}
                            >
                                Fecha Emisión {sortField === 'fechaEmision' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className="py-3 px-6 text-left cursor-pointer"
                                onClick={() => handleSort('fechaPago')}
                            >
                                Fecha Pago {sortField === 'fechaPago' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                className="py-3 px-6 text-left cursor-pointer"
                                onClick={() => handleSort('monto')}
                            >
                                Monto {sortField === 'monto' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className="py-3 px-6 text-left"
                            // onClick={() => handleSort('totalAbonado')}
                            >
                                Total Abonado
                                {/* Total Abonado {sortField === 'totalAbonado' && (sortOrder === 'asc' ? '▲' : '▼')} */}
                            </th>
                            <th className="py-3 px-6 text-left"
                            // onClick={() => handleSort('totalPendiente')}
                            >
                                Total Pendiente
                                {/* Total Pendiente {sortField === 'totalPendiente' && (sortOrder === 'asc' ? '▲' : '▼')} */}
                            </th>
                            <th className="py-3 px-6 text-left"
                                onClick={() => handleSort('estado')}
                            >
                                Estado {sortField === 'estado' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className="py-3 px-6 text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {loading ? (
                            <tr>
                                <td colSpan="7" className='text-center py-4'>Cargando...</td>
                            </tr>
                        ) : (
                            sortedFacturas.length > 0 ? (
                                sortedFacturas.map(factura => {
                                    const totalAdeudado = factura.monto - factura.total_abonado;

                                    return (
                                        <tr key={factura._id} className="border-b border-gray-200 hover:bg-gray-100">
                                            {filteredFacturas.length === 0 && <tr><td colSpan="10" className="text-center py-4">No hay facturas para mostrar.</td></tr>}
                                            {/* Checkbox de Selección Individual */}
                                            <td className="py-3 px-6 text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedFacturas.includes(factura._id)}
                                                    onChange={() => handleSelectFactura(factura._id)}
                                                    className="form-checkbox h-4 w-4 text-blue-600"
                                                />
                                            </td>
                                            <td className="py-3 px-6 text-left">{factura.numero}</td>
                                            <td className="py-3 px-6 text-left">{factura.clienteRut}</td>
                                            <td className="py-3 px-6 text-left">{clientes[factura.clienteRut] || 'Desconocido'}</td>
                                            <td className="py-3 px-6 text-left">{formatearFecha(factura.fechaEmision)}</td>
                                            <td className="py-3 px-6 text-left">{formatearFecha(factura.fechaPago)}</td>

                                            <td className="py-3 px-6 text-left">{factura.monto.toLocaleString('es-CL')}</td>
                                            <td className="py-3 px-6 text-left">{factura.total_abonado.toLocaleString('es-CL')}</td> {/* Total Abonado */}
                                            <td className="py-3 px-6 text-left">{totalAdeudado.toLocaleString('es-CL')}</td> {/* Total Adeudado */}
                                            <td className="py-3 px-6 text-left">
                                                <span className={`py-1 px-3 rounded-full text-xs ${factura.estado === 'pagada' ? 'bg-green-200 text-green-600' : factura.estado === 'abonada' ? 'bg-blue-200 text-blue-600' : 'bg-yellow-200 text-yellow-600'}`}>
                                                    {factura.estado}
                                                </span>
                                            </td>
                                            <td className="py-3 px-6 text-left">
                                                <div className="flex space-x-2">
                                                    <Link to={{
                                                        pathname: `/facturas/editar/${factura._id}`,
                                                        search: location.search,
                                                        state: { from: 'FacturaList', search: location.search },

                                                    }} aria-label="Editar factura" className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Link>

                                                    <button
                                                        onClick={() => handleFacturaAbonar(factura)} aria-label="Abonar factura"
                                                        className={`bg-yellow-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-yellow-600 ${factura.estado === 'pagada' ? 'opacity-50 cursor-not-allowed' : ''
                                                            }`}

                                                    >
                                                        <FontAwesomeIcon icon={faDollarSign} />
                                                    </button>

                                                    <button
                                                        onClick={() => eliminarFactura(factura._id)} aria-label="Eliminar factura"
                                                        className="bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-red-600"
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan="10" className='text-center py-4'>{mensaje || 'No hay facturas disponibles.'}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
};

export default FacturaList;
