// src/components/HonorarioList.jsx

import React, { useState, useEffect, useContext, useRef } from 'react';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faEdit, faTrash, faPlus, faSearch, faDollarSign, faClipboardList, faHandHoldingUsd, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';


const HonorarioList = () => {
  const { currentUser } = useContext(UserContext);
  const { id, role } = currentUser || {};
  const location = useLocation();
  const [honorarios, setHonorarios] = useState([]);
  const [clientes, setClientes] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const params = new URLSearchParams(location.search);
  const initialYear = params.get('year') ? Number(params.get('year')) : new Date().getFullYear();
  const initialMonth = params.get('month') ? Number(params.get('month')) : (new Date().getMonth() + 1);

  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [useDateRange, setUseDateRange] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isUserChangingFilters = useRef(false);


  // Estados para el resumen
  const [resumen, setResumen] = useState({
    cantidadMes: 0,
    totalHonorarios: 0,
    totalAbonado: 0,
    totalPendiente: 0,
  })


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let year = params.get('year');
    let month = params.get('month');
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    let shouldNavigate = false;

    // Si no hay año en la URL, establecer el año actual
    if (!year) {
      year = currentYear;
      params.set('year', year);
      shouldNavigate = true;
    }

    // Si no hay mes en la URL, establecer el mes actual
    if (!month) {
      month = currentMonth;
      params.set('month', month);
      shouldNavigate = true;
    }

    // Si faltaban parámetros, actualizar la URL
    if (shouldNavigate) {
      navigate(`?${params.toString()}`, { replace: true });
      return; // Evitar ejecutar el resto del useEffect en esta iteración
    }

    // Establecer los estados desde la URL
    setSelectedYear(Number(year));
    setSelectedMonth(Number(month));

    // Cargar honorarios solo si el cambio NO fue realizado por el usuario
    if (!isUserChangingFilters.current) {
      loadHonorarios();
    } else {
      isUserChangingFilters.current = false; // Resetear la referencia
    }

  }, [location.search, navigate])


  const handleYearChange = (e) => {
    isUserChangingFilters.current = true; // Indica que el cambio es realizado por el usuario
    const year = Number(e.target.value);
    setSelectedYear(year);
    const params = new URLSearchParams(location.search);
    params.set('year', year);
    navigate(`?${params.toString()}`);
  };

  const handleMonthChange = (e) => {
    isUserChangingFilters.current = true; // Indica que el cambio es realizado por el usuario
    const month = Number(e.target.value);
    setSelectedMonth(month);
    const params = new URLSearchParams(location.search);
    params.set('month', month);
    navigate(`?${params.toString()}`);
  };


  const loadHonorarios = async () => {
    setLoading(true);
    let url = 'https://sistemaoh-backend.onrender.com/api/honorarios';

    if (useDateRange && startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    } else {
      url += `?year=${selectedYear}&month=${selectedMonth}`;
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setHonorarios(data);

      // Verificar si data es un arreglo y contiene los campos necesarios
      if (Array.isArray(data)) {
        calcularResumen(data);
        setMensaje('');
      } else {
        setMensaje('Formato de datos inesperado.');
      }
    } catch (error) {
      console.error(error);
      setMensaje('Error al cargar los honorarios.');
      setResumen({
        cantidadMes: 0,
        totalHonorarios: 0,
        totalAbonado: 0,
        totalPendiente: 0,
      });
    } finally {
      setLoading(false);
    }
  }

  // Función para calcular los datos del resumen
  const calcularResumen = (datos) => {
    const cantidadMes = datos.length;
    const totalHonorarios = datos.reduce((acc, curr) => acc + (curr.monto || 0), 0);
    const totalAbonado = datos.reduce((acc, curr) => acc + (curr.total_abonado || 0), 0);
    const totalPendiente = datos.reduce((acc, curr) => acc + ((curr.monto || 0) - (curr.total_abonado || 0)), 0);

    setResumen({
      cantidadMes,
      totalHonorarios,
      totalAbonado,
      totalPendiente,
    });
  }


  useEffect(() => {
    calcularResumen(honorarios);
  }, [honorarios])



  const fetchHonorarios = async () => {
    const token = localStorage.getItem('token');
    setLoading(true);
    let url = 'https://sistemaoh-backend.onrender.com/api/honorarios';
    //let url = 'http://localhost:5000/api/honorarios'

    if (useDateRange && startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    } else {
      url += `?year=${selectedYear}&month=${selectedMonth}`;
    }

    try {
      const response = await api.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });


      //console.log('Honorarios:', response.data);
      setHonorarios(response.data);
      setMensaje('');
    } catch (error) {
      console.error('Error al obtener honorarios:', error);
      setMensaje('No se pudieron cargar los honorarios.');
      Swal.fire('Error', 'No se pudieron cargar los honorarios.', 'error');
    } finally {
      setLoading(false);
    }
  }




  // Función para obtener todos los clientes
  const fetchClientes = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/clientes', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const clientesData = {};
      response.data.forEach((cliente) => {
        clientesData[cliente.rut] = cliente.nombre;
      });
      setClientes(clientesData);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
      Swal.fire('Error', 'No se pudieron cargar los clientes.', 'error');
    }
  }



  const eliminarHonorario = async (id) => {
    const token = localStorage.getItem('token');
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/honorarios/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          Swal.fire('Eliminado', 'Honorario eliminado correctamente', 'success');
          fetchHonorarios();
        } catch (error) {
          console.error('Error al eliminar honorario:', error);
          Swal.fire('Error', 'No se pudo eliminar el honorario.', 'error');
        }
      }
    });
  }

  // Función para formatear la fecha en DD-MM-YYYY
  const formatearFecha = (fechaISO) => {
    if (!fechaISO) return 'N/A';
    const fecha = new Date(fechaISO);
    const dia = String(fecha.getUTCDate()).padStart(2, '0');
    const mes = String(fecha.getUTCMonth() + 1).padStart(2, '0'); // Los meses son base 0
    const anio = fecha.getUTCFullYear();
    return `${dia}-${mes}-${anio}`;
  }


  const filteredHonorarios = honorarios.filter((honorario) => {
    const clienteNombre = honorario.clienteNombre || '';
    const clienteRut = honorario.clienteRut || '';
    const searchLower = searchTerm.toLowerCase();
    return (
      clienteNombre.toLowerCase().includes(searchLower) ||
      clienteRut.toLowerCase().includes(searchLower)
    );
  })

  return (
    <div className="p-6 min-h-screen bg-gray-50">
      <h1 className="font-bold mb-6 text-center text-3xl text-gray-800">Lista de Honorarios</h1>

      {/* Recuadro de Resumen Mejorado */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-8">
        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
          <FontAwesomeIcon icon={faClipboardList} className="text-blue-500 text-2xl mr-3" />
          <div>
            <h3 className="text-sm font-medium text-gray-700">Honorarios del Mes</h3>
            <p className="text-lg font-semibold text-blue-600">{resumen.cantidadMes}</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
          <FontAwesomeIcon icon={faDollarSign} className="text-green-500 text-2xl mr-3" />
          <div>
            <h3 className="text-sm font-medium text-gray-700">Total Honorarios</h3>
            <p className="text-lg font-semibold text-green-600">${resumen.totalHonorarios.toLocaleString('es-CL')}</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
          <FontAwesomeIcon icon={faHandHoldingUsd} className="text-yellow-500 text-2xl mr-3" />
          <div>
            <h3 className="text-sm font-medium text-gray-700">Total Abonado</h3>
            <p className="text-lg font-semibold text-yellow-600">${resumen.totalAbonado.toLocaleString('es-CL')}</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 text-2xl mr-3" />
          <div>
            <h3 className="text-sm font-medium text-gray-700">Total Pendiente</h3>
            <p className="text-lg font-semibold text-red-600">${resumen.totalPendiente.toLocaleString('es-CL')}</p>
          </div>
        </div>
      </div>


      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end">
          {/* Filtro por Año y Mes */}
          {!useDateRange && (
            <div className="flex flex-col">
              <label className="mb-1 text-gray-600 font-medium">Año</label>
              <select
                value={selectedYear}
                onChange={handleYearChange}
                className="border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                {Array.from({ length: 10 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {!useDateRange && (
            <div className="flex flex-col">
              <label className="mb-1 text-gray-600 font-medium">Mes</label>
              <select
                value={selectedMonth}
                onChange={handleMonthChange}
                className="border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {month.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Toggle para cambiar entre mes/año o rango de fechas */}
          <div className="flex flex-col">
            <label className="mb-1 text-gray-600 font-medium">Filtrar por:</label>
            <div className="flex items-center space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="filterType"
                  checked={!useDateRange}
                  onChange={() => setUseDateRange(false)}
                  className="mr-2"
                />
                Mes/Año
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="filterType"
                  checked={useDateRange}
                  onChange={() => setUseDateRange(true)}
                  className="mr-2"
                />
                Rango de Fechas
              </label>
            </div>
          </div>

          {/* Si se selecciona rango de fechas */}
          {useDateRange && (
            <div className="col-span-2 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-1 text-gray-700 font-medium">Fecha Inicio</label>
                <input
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 text-gray-700 font-medium">Fecha Fin</label>
                <input
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>
          )}
        </div>
        {/* Botones Mejorados: Nuevo Honorario a la Izquierda y Cargar Honorarios a la Derecha */}
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 mt-6">
          {/* Botón Nuevo Honorario */}
          <button
            onClick={() => navigate(`/honorario-nuevo${location.search}`)}
            className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md shadow hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:outline-none"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Nuevo Honorario
          </button>

          {/* Botón Cargar Honorarios */}
          <button
            onClick={loadHonorarios}
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2" />
            Cargar Honorarios
          </button>
        </div>
      </div>
      {/* Buscador */}
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Buscar por nombre o RUT"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 p-2 pl-10 rounded-full w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
        <span className="absolute right-3 top-2">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-400" />
        </span>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Cliente</th>
              <th className="py-3 px-6 text-left">RUT</th>
              <th className="py-3 px-6 text-left">Fecha Emisión</th>
              <th className="py-3 px-6 text-left">Monto</th>
              <th className="py-2 px-4 border">Descripción</th> 
              <th className="py-3 px-6 text-left">Total Abonado</th>
              <th className="py-3 px-6 text-left">Saldo Pendiente</th>
              <th className="py-3 px-6 text-left">Estado</th>
              <th className="py-3 px-6 text-center">Acciones</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {loading ? (
              <tr>
                <td colSpan="8" className='text-center py-4'>Cargando...</td>
              </tr>
            ) : (
              filteredHonorarios.length > 0 ? (
                filteredHonorarios.map((honorario) => {
                  const totalAdeudado = honorario.monto - honorario.total_abonado;
                  return (
                    <tr key={honorario._id} className="border-t">
                      <td className="px-4 py-2">
                        {honorario.clienteNombre || 'Desconocido'}
                      </td>
                      <td className="px-4 py-2">{honorario.clienteRut || 'N/A'}</td>
                      <td className="px-4 py-2">{formatearFecha(honorario.fechaEmision)}</td>
                      <td className="px-4 py-2">${honorario.monto.toLocaleString()}</td>
                      <td className="py-2 px-4 border">{honorario.descripcion}</td>
                      <td className="px-4 py-2">${honorario.total_abonado.toLocaleString()}</td>
                      <td className="px-4 py-2">{totalAdeudado.toLocaleString('es-CL')}</td>
                      <td className="px-4 py-2 capitalize"><span className={`py-1 px-3 rounded-full text-xs ${honorario.estado === 'pagada' ? 'bg-green-200 text-green-600' : honorario.estado === 'abonada' ? 'bg-blue-200 text-blue-600' : 'bg-yellow-200 text-yellow-600'}`}>
                        {honorario.estado}
                      </span></td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex space-x-2">
                          <button
                            onClick={() => navigate(`/honorarios/editar/${honorario._id}${location.search}`)}
                            className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            onClick={() => navigate(`/abonosHonorarios/${honorario._id}${location.search}`, { state: { tipo: 'honorario' } })}
                            className={`bg-yellow-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-yellow-600 ${honorario.estado === 'pagada' ? 'opacity-50 cursor-not-allowed' : ''}`}

                          >
                            <FontAwesomeIcon icon={faDollarSign} />
                          </button>
                          <button
                            onClick={() => eliminarHonorario(honorario._id)}
                            className="bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-red-600"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" className='text-center py-4'>{mensaje || 'No hay honorarios disponibles.'}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default HonorarioList;